<template>
	<div>
		<iframe :src="iframeSrc" :height="iframeHeight" />
		<div class="info-callout p-3">
			<img src="../../appzmudri/_layouts/default/img/info-icon.png"/>
			<p class="font-weight-bold">
				Ak vám nejde načítať dokument alebo kvíz, vyskúšajte niektoré z tipov:

				<ul>
					<li>Refresh stránky</li>
					<li>Prekliknutie sa na iné stránky a naspäť</li>
					<li>Odhlásenie/prihlásenie</li>
				</ul>

				V prípade pretrvávajúcich problémov nás kontaktuje na
				<a class="link" href="mailto:team@zmudri.sk">
					team@zmudri.sk
				</a>
			</p>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	props: {
		quizEmbed: {
			type: String,
			required: true
		},
		quizEmbedHeight: {
			type: String,
			required: true
		},
		quizEmailFieldId: {
			type: String
		}
	},
	computed: {
		...mapGetters('wAuth', [
			'user'
		]),
		iframeSrc() {
			let src = `https://docs.google.com/forms/d/e/${this.quizEmbed}/viewform?embedded=true`
			if (this.quizEmailFieldId && this.user && this.user.email) {
				src = `${src}&${this.quizEmailFieldId}=${this.user.email}`
			}

			return encodeURI(src)
		},
		iframeHeight() {
			return parseFloat(this.quizEmbedHeight) + 100
		}
	}
}
</script>

<style lang="scss" scoped>
.info-callout {
	font-family: 'poppins';
	max-width: 467px;
	background: #fff5d1;
	border: 1px solid #e5d8a7;
	border-radius: 4px;
	margin: 20px auto;
	display: flex;
	align-items: center;

	img {
		margin-right: 30px;
	}

	p {
		color: #998c5c;
		white-space: normal;
		margin: 0;

		a.link {
			font-weight: 700;
			color: inherit;
			text-decoration: underline;
		}
	}

	@media only screen and (max-width: 480px){
		max-width: 20em;
	}
}
</style>